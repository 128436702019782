import React from 'react'
import { createPortal } from 'react-dom'
import { animated } from 'react-spring'

import { useSlideDown } from '../../hooks/useSlide'
import { cn } from '../../utils/cn'
import Modal, { ModalSize } from './Modal'

export function ResponsiveModal({
  className,
  classNameBodyContainer,
  open,
  ...props
}: Omit<React.ComponentProps<typeof Modal>, 'title'> & { open: boolean; title?: string }) {
  const transition = useSlideDown(open, undefined)

  return createPortal(
    <>
      {transition(
        (style, item, { key }) =>
          item && (
            <animated.div
              className={cn('absolute z-[102]', { 'left-[45%]': props.size === ModalSize.Thin })}
              key={key}
              style={style}
            >
              <div className="h-screen w-screen pt-[2%]">
                <Modal
                  className="m-auto h-[90vh] max-h-[740px] w-[80vw] max-w-[580px] shadow-[0px_4px_20px_0px_rgba(48,_55,_65,_0.3)] [&_>_div:first-of-type]:hidden"
                  classNameBodyContainer="h-0"
                  title=""
                  {...props}
                />
              </div>
            </animated.div>
          )
      )}
    </>,
    document.body
  )
}
